import React, { FC } from "react";
import {Button} from "primereact/button";

const ShowMoreButton: FC<{label?: string; onClick?: () => void}> = ({label = "Zobacz więcej", onClick}) => {

    return (
        <Button label={label} onClick={onClick} outlined style={{height: 20, fontSize: 10, borderRadius: 40}} />
    );
};

export default ShowMoreButton;
