import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate, useParams} from "react-router-dom";
import {PrimeIcons} from "primereact/api";
import {
    ProductSourceReadTableDto,
    useFindProductByIdQuery, useFindVariantsByProductIdOrSourceIdQuery,
    useGetProductSourceReadTableQuery
} from "../../../api/mintpriceApi";
import {isAdmin} from "../../../utils/loggedUserUtils";
import {useAppSelector} from "../../../app/hooks";
import {selectProductState, setData} from "../../../slices/productsSlice";
import productSourceReadTableActionTemplate from "./Actions";
import SelectInput from "../../../components/form/SelectInput";
import {useNavTitle} from "../../../utils/navbarUtils";
import VariantInput from "../../../components/form/VariantInput";

const ProductSourceReadTable: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {id: productId = ""} = useParams();

    const {variantSize} = useAppSelector(selectProductState);

    const {isLoading, data} = useGetProductSourceReadTableQuery({
        productId: productId,
        variantSize: variantSize
    });
    const {data: productData} = useFindProductByIdQuery({
        id: productId
    });

    const {data: variantSizes} = useFindVariantsByProductIdOrSourceIdQuery({productId: productId});
    const variantSizeOptions = variantSizes?.map(item => {
        return {value: item, label: item}
    }) || [];

    const columns = [
        {field: "sourceTypeLabel", header: "Źródło", sortable: true},
        {field: "price", header: "Cena", sortable: true, body: (row: ProductSourceReadTableDto) => row.latestPrice ? row.latestPrice?.toFixed(2) + " zł" : "Brak danych"},
        {field: "stock", header: "Liczba na magazynie", sortable: true, body: (row: ProductSourceReadTableDto) => row.stock || "Brak danych"},
        {
            field: "",
            header: "",
            body: productSourceReadTableActionTemplate,
        },
    ];

    useNavTitle("Szczegołowa analiza produktu", 'W tym panelu widzisz raport cenowy produktu');

    const headerButtons = (
        <>
            <Button onClick={() => navigate(`/${routes.PRODUCTS}`)} label="Wróc do produktów"
                    icon={PrimeIcons.BACKWARD}/>
            <Button onClick={() => navigate(routes.ADD)} label="Dodaj" icon={PrimeIcons.PLUS} visible={isAdmin()}/>
        </>
    );

    return (
        <div className="p-4">
            <div className="card">
                <div className="flex">
                    <div>
                        <h2 style={{width: 400}}>{productData?.name}</h2>
                        <img src={productData?.photoUrl} alt={productData?.name} width={300}/>
                    </div>
                    <div style={{width: "max-content", marginTop: 15}}>
                        <VariantInput
                            id="variantSize"
                            options={variantSizeOptions}
                            name="variantSize"
                            value={variantSize}
                            label=""
                            setProperty={setData}
                        />
                    </div>
                </div>
            </div>
            <TableWrapper
                isLoading={isLoading}
                data={data}
                columns={columns}
                addButton={headerButtons}
                stateKey="product-source-read-table"
            />
        </div>
    );
};

export default ProductSourceReadTable;
