import React, {FC, useState} from "react";
import {Calendar} from "primereact/calendar";
import {Nullable} from "primereact/ts-helpers";

const RightMenu: FC<{}> = () => {
    const [date, setDate] = useState<Nullable<Date>>(new Date());

    return (
        <div className="card flex justify-content-center">
            <Calendar value={date} locale="pl" onChange={(e) => setDate(e.value)} inline />
        </div>
    )
};

export default RightMenu;
