import React, {FC, useEffect, useState} from "react";
import {Chart} from "primereact/chart";
import SelectInput from "../../components/form/SelectInput";
import {initialSourceState, selectProductState, setData} from "../../slices/productsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useFindShopsKpiChartBySourceTypeQuery, useGetSourceTypesQuery} from "../../api/mintpriceApi";
import {ProgressSpinner} from "primereact/progressspinner";
import {getSourceTypeLabel} from "../../utils/labels";

var colors = [
    '#14b8a6', '#FF5733', '#33FF57', '#5733FF', '#FF33E9', '#33E9FF',
    '#FF3333', '#33FF33', '#3333FF', '#FFFF33', '#FF33FF',
    '#33FFFF', '#7F33FF', '#FF7F33', '#33FF7F', '#7FFF33',
    '#7F33FF', '#337FFF', '#FF337F', '#33FF7F', '#7FFF33'
];

const getRandomColor = (index: number) => {
    return colors[index];
}

const generateLast7Days = () => {
    var dates = [];
    var today = new Date();
    for (var i = 0; i < 7; i++) {
        var date = new Date(today);
        date.setDate(today.getDate() - i);
        var formattedDate = date.toISOString().slice(0, 10);
        dates.push(formattedDate);
    }
    dates.reverse();
    return dates;
}

const Shops: FC<{}> = ({}) => {
    const {data: shopKPIs = [], isFetching} = useFindShopsKpiChartBySourceTypeQuery();

    const [chartProductData, setChartProductData] = useState({});
    const [chartVariantData, setChartVariantData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const getSourceTypeLabel = (sourceType: string) => {
        return sourceTypes?.find(item => item?.code === sourceType)?.name;
    }

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartProductData({
            labels: generateLast7Days(),

            datasets: Object.keys(shopKPIs).map((sourceType, index) => {
                return {
                    label: getSourceTypeLabel(sourceType),
                    // @ts-ignore
                    data: shopKPIs[sourceType].map(item => {
                        return {
                            x: item.readDate,
                            y: item.availableProducts
                        }
                    }),
                    fill: false,
                    borderColor: getRandomColor(index),
                    tension: 0.4
                }
            }),
        });
        setChartVariantData({
            labels: generateLast7Days(),
            datasets: Object.keys(shopKPIs).map((sourceType, index) => {
                return {
                    label: getSourceTypeLabel(sourceType),
                    // @ts-ignore
                    data: shopKPIs[sourceType].map(item => {
                        return {
                            x: item.readDate,
                            y: item.availableVariants
                        }
                    }),
                    fill: false,
                    borderColor: getRandomColor(index),
                    tension: 0.4
                }
            }),
        })
        setChartOptions(options);
    }, [shopKPIs]);

    const {data: sourceTypes} = useGetSourceTypesQuery();

    const sourceOptions = sourceTypes?.map(item => {
        return {value: item.code, label: item.name}
    }) || [];

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setData({property: 'source', value: initialSourceState}));
    }, []);

    return (
        <>
            <div className="card">
                <div className="flex flex-row sm:justify-content-start align-items-center gap-5">
                    <h2>Analiza konkurencyjności</h2>
                </div>
            </div>

            <div className="flex flex-column gap-5">
                <div className="card">
                    <div>Liczba produktów</div>
                    {
                        isFetching ? <ProgressSpinner style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}/> :
                            <Chart type="line" data={chartProductData} options={chartOptions}/>
                    }
                </div>
                <div className="card">
                    <div>Liczba dostępnych wariantów</div>
                    {
                        isFetching ? <ProgressSpinner style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}/> :
                            <Chart type="line" data={chartVariantData} options={chartOptions}/>
                    }
                </div>
            </div>
        </>
    );
};

export default Shops;
