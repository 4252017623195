import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import {useFindAllNotificationsQuery} from "../../api/mintpriceApi";
import {useNavTitle} from "../../utils/navbarUtils";
import notificationActionTemplate from "./Actions";

const Notifications: FC<{}> = ({}) => {
    useNavTitle("Powiadomienia", "");

    const {isLoading, data} = useFindAllNotificationsQuery({
        page: 0,
        size: 10000
    });

    const isDataLoading = isLoading;

    const columns = [
        {field: "title", header: "Tytuł", sortable: true, exportable: true},
        {field: "notificationDate", header: "Data", sortable: true, exportable: true},
        {
            field: "",
            header: "",
            body: notificationActionTemplate,
            exportable: false
        },
    ];

    const rowClass = (data: any) => {
        return {
            'bg-primary': !data.read
        };
    };

    return (
        <div className="p-4">
            <TableWrapper
                isLoading={isDataLoading}
                data={data?.content}
                columns={columns}
                stateKey="notifications-list"
                rowClassName={rowClass}
            />
        </div>
    );
};

export default Notifications;
