import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import {ChangeDto, useFindAllChangesQuery} from "../../api/mintpriceApi";
import {useNavTitle} from "../../utils/navbarUtils";

const Changes: FC = () => {
    useNavTitle("Ostatnie zmiany cenowe konkurencji", "");

    const {data, isLoading} = useFindAllChangesQuery({
        page: 0,
        size: 10000,
        sort: ["changeDate,desc"]
    });

    const columns = [
        {field: "", header: "", body: (row: ChangeDto) => <img alt={row.productName} src={row.photoUrl} width={100} />},
        {field: "productName", header: "Nazwa", sortable: true, exportable: true},
        {field: "variantSize", header: "Rozmiar", sortable: true, exportable: true},
        {field: "sourceTypeLabel", header: "Sklep", sortable: true, exportable: true},
        {field: "changeDate", header: "Data zmiany", sortable: true, exportable: true},
        {field: "priceBefore", header: "Cena przed", body: (row: ChangeDto) => row.priceBefore + "zł", sortable: true, exportable: true},
        {field: "priceAfter", header: "Cena po", body: (row: ChangeDto) => row.priceAfter + "zł", sortable: true, exportable: true},
        {field: "changeRate", header: "Różnica [%]", body: (row: ChangeDto) => row.changeRate + "%", sortable: true, exportable: true},
        {field: "cheapestPrice", header: "Najtańsza wartość rynkowa", body: (row: ChangeDto) => row.cheapestPrice + "zł", sortable: true, exportable: true},
        {field: "cheapestSourceTypeLabel", header: "Najtańszy sklep", sortable: true, exportable: true},
    ];

    const headerButtons = (
        <>
        </>
    );

    return (
        <div className="p-4">
            <TableWrapper
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                addButton={headerButtons}
                stateKey="changes-list"
            />
        </div>
    );
};

export default Changes;
