import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {cacheTags} from "./cacheTags";
import keycloak from "../Keycloak";

export const API = "api";

const baseQuery = fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers) => {
        const token = keycloak.token;
        if (!!token && token !== "") {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const api = createApi({
    reducerPath: API,
    tagTypes: Object.values(cacheTags),
    baseQuery: baseQuery,
    endpoints: () => ({}),
    refetchOnMountOrArgChange: true,
});
