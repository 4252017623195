import React, {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Chart} from "primereact/chart";
import {
    useFindSourceByIdQuery, useFindSourceChartBySourceIdQuery, useFindVariantsByProductIdOrSourceIdQuery
} from "../../api/mintpriceApi";
import {useAppSelector} from "../../app/hooks";
import {selectProductState, setData} from "../../slices/productsSlice";
import {ProgressSpinner} from "primereact/progressspinner";
import {useNavTitle} from "../../utils/navbarUtils";
import VariantInput from "../../components/form/VariantInput";

var colors = [
    '#14b8a6'
];

const getRandomColor = (index: number) => {
    return colors[index];
}

const generateLast7Days = () => {
    var dates = [];
    var today = new Date();
    for (var i = 0; i < 8; i++) {
        var date = new Date(today);
        date.setDate(today.getDate() - i);
        var formattedDate = date.toISOString().slice(0, 10);
        dates.push(formattedDate);
    }
    dates.reverse();
    return dates;
}

const SourceReads: FC<{}> = ({}) => {
    const {id: sourceId = ""} = useParams();
    const {variantSize, sourceReadQuantityType} = useAppSelector(selectProductState);

    const {data: chartBySourceId = [], isFetching} = useFindSourceChartBySourceIdQuery({sourceId: sourceId});

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        console.log(generateLast7Days());

        const data = {
            labels: generateLast7Days(),
            datasets: [{
                    label: variantSize || "Wybierz rozmiar",
                    // @ts-ignore
                    data: chartBySourceId[variantSize]?.map(item => {
                        return {
                            x: item.readDate,
                            y: sourceReadQuantityType === "price" ? item.price : item.stock
                        }
                    }),
                    fill: false,
                    borderColor: getRandomColor(1),
                    tension: 0.4
                }]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        // @ts-ignore
                        callback: (label, index, labels) => {
                            return sourceReadQuantityType === "price" ? (+label).toFixed(2).toString().replace(".", ",") + ' zł' : label;
                        },
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [chartBySourceId, variantSize, sourceReadQuantityType]);

    const {data: source} = useFindSourceByIdQuery({id: sourceId})

    const {data: availableVariants} = useFindVariantsByProductIdOrSourceIdQuery({sourceId: sourceId});
    const variantSizeOptions = availableVariants?.map(item => {
        return {value: item, label: item}
    }) || [];

    useNavTitle("Szczegołowa analiza źródła produktu", 'W tym panelu widzisz wykres cenowy źródła produktu');

    return (
        <div className="p-4">
            <div className="card">
                <div className="flex flex-row sm:justify-content-start align-items-center gap-5">
                    <img src={source?.photoUrl} alt={source?.name} height={100}/>
                    <h2>{source?.name}</h2>
                    <h4>{source?.sourceTypeLabel}</h4>
                </div>
                <VariantInput
                    id="variantSize"
                    options={variantSizeOptions}
                    name="variantSize"
                    value={variantSize}
                    label=""
                    setProperty={setData}
                />
            </div>
            <div className="card">
                {
                    isFetching ? <ProgressSpinner style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}/> :
                        <Chart type="line" data={chartData} options={chartOptions}/>
                }
            </div>
        </div>
    );
};

export default SourceReads;
