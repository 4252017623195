import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";
import {useDeleteProductMutation} from "../../api/mintpriceApi";
import {isAdmin} from "../../utils/loggedUserUtils";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [deleteVisible, setDeleteVisible] = React.useState(false);

    const [deleteProduct] = useDeleteProductMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.DATABASE}
                    onClick={() => navigate(`${row.id}/${routes.SOURCES}`)}
                    label="Zobacz źródła"
                    visible={isAdmin()}
                />
                <Button
                    icon={PrimeIcons.TABLE}
                    onClick={() => navigate(`/${routes.PRODUCTS}/${row.id}/${routes.TABLE}`)}
                    label="Tabela raport"
                />
                <Button
                    icon={PrimeIcons.CHART_LINE}
                    onClick={() => navigate(`/${routes.PRODUCTS}/${row.id}/${routes.CHART}`)}
                    label="Historia raport"
                />
                <Button
                    icon={PrimeIcons.TRASH}
                    onClick={() => setDeleteVisible(true)}
                    label="Usuń"
                    visible={isAdmin()}
                />
                <ConfirmDialog
                    visible={deleteVisible && isAdmin()}
                    onHide={() => setDeleteVisible(false)}
                    message="Jesteś pewny? Produkt zostanie usunięty na zawsze z systemu"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={() => deleteProduct({id: row.id})}
                />
            </div>
        </>
    );
};

const productActionTemplate = (row: any) => <Actions row={row}/>;
export default productActionTemplate;
