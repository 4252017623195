import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import {
    initialProductSourceState,
    selectProductState,
    setData, setProductSourceProperty
} from "../../../slices/productsSlice";
import {
    useAssignSourceToProductMutation,
    useFindAllSourcesBySourceTypeQuery, useGetSourceTypesQuery
} from "../../../api/mintpriceApi";
import SelectInput from "../../../components/form/SelectInput";

const UpdateProductSource: FC<{}> = ({}) => {
    const {id: productId = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [
        assignSourceToProduct,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useAssignSourceToProductMutation();

    const {productSource, sourceType} = useAppSelector(selectProductState);

    const {data: sourceTypes} = useGetSourceTypesQuery();

    const {data: sources} = useFindAllSourcesBySourceTypeQuery({
        page: 0,
        size: 10000,
        sourceType: sourceType,
    })

    const {sourceId} = productSource;

    const isSuccess = isCreateSuccess;

    useEffect(() => {
        dispatch(setData({property: "productSource", value: initialProductSourceState}));
        dispatch(setProductSourceProperty({property: "productId", value: productId}));

        if (isSuccess) {
            navigate(`/${routes.PRODUCTS}/${productId}/${routes.SOURCES}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        assignSourceToProduct({productSourceDto: productSource});
    };

    return (
        <div className="p-4">
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {`Dodaj źródło do produktu`}
                </h3>

                <SelectInput
                    id="sourceType"
                    options={sourceTypes?.map(item => {
                        return {value: item.code, label: item.name}
                    }) ?? []}
                    name="sourceType"
                    value={sourceType}
                    label="Wybierz sklep"
                    setProperty={setData}
                />

                <SelectInput
                    id="sourceId"
                    options={sources?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) ?? []}
                    name="sourceId"
                    value={sourceId}
                    label="Źródło"
                    setProperty={setProductSourceProperty}
                    filter
                />

                <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </div>
    );
};

export default UpdateProductSource;
