import React, { FC } from "react";
import {Image} from "primereact/image";
import {Button} from "primereact/button";

const ChangeRow: FC<{productName: string, imageSrc: string}> = ({productName, imageSrc}) => {

    return (
        <div className="flex flex-row gap-2">
            <Image src={imageSrc} width="60"/>
            <div style={{fontSize: 16, fontWeight: "bold", alignContent: "center"}}>
                {productName}
            </div>
            <div style={{alignContent: "center"}}>
                <Button icon="pi pi-arrow-right" rounded aria-label="Idź do" style={{width: 30, height: 30}} />
            </div>
        </div>
    );
};

export default ChangeRow;
