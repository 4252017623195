import { api } from "./api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    scrapSource: build.mutation<ScrapSourceApiResponse, ScrapSourceApiArg>({
      query: (queryArg) => ({
        url: `/sources/scrap`,
        method: "POST",
        params: { sourceType: queryArg.sourceType },
      }),
    }),
    scrapBatchSourceRead: build.mutation<
      ScrapBatchSourceReadApiResponse,
      ScrapBatchSourceReadApiArg
    >({
      query: (queryArg) => ({
        url: `/source-reads`,
        method: "POST",
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    triggerScrapSources: build.mutation<
      TriggerScrapSourcesApiResponse,
      TriggerScrapSourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/scrapers/sources`,
        method: "POST",
        params: { sourceType: queryArg.sourceType },
      }),
    }),
    triggerScrapReads: build.mutation<
      TriggerScrapReadsApiResponse,
      TriggerScrapReadsApiArg
    >({
      query: (queryArg) => ({
        url: `/scrapers/reads`,
        method: "POST",
        params: { sourceType: queryArg.sourceType },
      }),
    }),
    findAllProducts: build.query<
      FindAllProductsApiResponse,
      FindAllProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/products`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    createProduct: build.mutation<
      CreateProductApiResponse,
      CreateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/products`,
        method: "POST",
        body: queryArg.productDto,
      }),
    }),
    findProductSourcesByProductId: build.query<
      FindProductSourcesByProductIdApiResponse,
      FindProductSourcesByProductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/product-sources`,
        params: {
          productId: queryArg.productId,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    assignSourceToProduct: build.mutation<
      AssignSourceToProductApiResponse,
      AssignSourceToProductApiArg
    >({
      query: (queryArg) => ({
        url: `/product-sources`,
        method: "POST",
        body: queryArg.productSourceDto,
      }),
    }),
    unassignSourceToProduct: build.mutation<
      UnassignSourceToProductApiResponse,
      UnassignSourceToProductApiArg
    >({
      query: (queryArg) => ({
        url: `/product-sources`,
        method: "DELETE",
        body: queryArg.productSourceDto,
      }),
    }),
    uploadImage: build.mutation<UploadImageApiResponse, UploadImageApiArg>({
      query: (queryArg) => ({
        url: `/images`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getImage: build.mutation<GetImageApiResponse, GetImageApiArg>({
      query: (queryArg) => ({
        url: `/images/url`,
        method: "POST",
        body: queryArg.getImageRequest,
      }),
    }),
    findAllChanges: build.query<
      FindAllChangesApiResponse,
      FindAllChangesApiArg
    >({
      query: (queryArg) => ({
        url: `/changes`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    initAnalyse: build.mutation<InitAnalyseApiResponse, InitAnalyseApiArg>({
      query: () => ({ url: `/changes`, method: "POST" }),
    }),
    setNotificationAsRead: build.mutation<
      SetNotificationAsReadApiResponse,
      SetNotificationAsReadApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications/${queryArg.id}/read`,
        method: "PATCH",
      }),
    }),
    findVariantsByProductIdOrSourceId: build.query<
      FindVariantsByProductIdOrSourceIdApiResponse,
      FindVariantsByProductIdOrSourceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/variants`,
        params: { productId: queryArg.productId, sourceId: queryArg.sourceId },
      }),
    }),
    findAllSourcesBySourceType: build.query<
      FindAllSourcesBySourceTypeApiResponse,
      FindAllSourcesBySourceTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/sources`,
        params: {
          sourceType: queryArg.sourceType,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    findSourceReads: build.query<
      FindSourceReadsApiResponse,
      FindSourceReadsApiArg
    >({
      query: (queryArg) => ({
        url: `/sources/${queryArg.sourceId}/reads`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    findSourceById: build.query<
      FindSourceByIdApiResponse,
      FindSourceByIdApiArg
    >({
      query: (queryArg) => ({ url: `/sources/${queryArg.id}` }),
    }),
    getSourceTypes: build.query<
      GetSourceTypesApiResponse,
      GetSourceTypesApiArg
    >({
      query: () => ({ url: `/source-types` }),
    }),
    getProductSourceReadTable: build.query<
      GetProductSourceReadTableApiResponse,
      GetProductSourceReadTableApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/table`,
        params: { variantSize: queryArg.variantSize },
      }),
    }),
    getProductSourceReadChart: build.query<
      GetProductSourceReadChartApiResponse,
      GetProductSourceReadChartApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/chart`,
        params: {
          variantSize: queryArg.variantSize,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
    findProductById: build.query<
      FindProductByIdApiResponse,
      FindProductByIdApiArg
    >({
      query: (queryArg) => ({ url: `/products/${queryArg.id}` }),
    }),
    deleteProduct: build.mutation<
      DeleteProductApiResponse,
      DeleteProductApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    findAllNotifications: build.query<
      FindAllNotificationsApiResponse,
      FindAllNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    findNotificationById: build.query<
      FindNotificationByIdApiResponse,
      FindNotificationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/notifications/${queryArg.id}` }),
    }),
    countAllUnreadNotifications: build.query<
      CountAllUnreadNotificationsApiResponse,
      CountAllUnreadNotificationsApiArg
    >({
      query: () => ({ url: `/notifications/count` }),
    }),
    getDashboardShopReview: build.query<
      GetDashboardShopReviewApiResponse,
      GetDashboardShopReviewApiArg
    >({
      query: () => ({ url: `/dashboard/shop-review` }),
    }),
    getDashboardKpi: build.query<
      GetDashboardKpiApiResponse,
      GetDashboardKpiApiArg
    >({
      query: () => ({ url: `/dashboard/kpi` }),
    }),
    findSourceChartBySourceId: build.query<
      FindSourceChartBySourceIdApiResponse,
      FindSourceChartBySourceIdApiArg
    >({
      query: (queryArg) => ({ url: `/charts/sources/${queryArg.sourceId}` }),
    }),
    findShopsKpiChartBySourceType: build.query<
      FindShopsKpiChartBySourceTypeApiResponse,
      FindShopsKpiChartBySourceTypeApiArg
    >({
      query: () => ({ url: `/charts/shops-kpi` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ScrapSourceApiResponse = unknown;
export type ScrapSourceApiArg = {
  sourceType:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
};
export type ScrapBatchSourceReadApiResponse = unknown;
export type ScrapBatchSourceReadApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type TriggerScrapSourcesApiResponse = unknown;
export type TriggerScrapSourcesApiArg = {
  sourceType?: (
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL"
  )[];
};
export type TriggerScrapReadsApiResponse = unknown;
export type TriggerScrapReadsApiArg = {
  sourceType?: (
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL"
  )[];
};
export type FindAllProductsApiResponse = /** status 200 OK */ PageProductDto;
export type FindAllProductsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateProductApiResponse = /** status 200 OK */ ProductDto;
export type CreateProductApiArg = {
  productDto: ProductDto;
};
export type FindProductSourcesByProductIdApiResponse =
  /** status 200 OK */ PageSourceDto;
export type FindProductSourcesByProductIdApiArg = {
  productId: string;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type AssignSourceToProductApiResponse = unknown;
export type AssignSourceToProductApiArg = {
  productSourceDto: ProductSourceDto;
};
export type UnassignSourceToProductApiResponse = unknown;
export type UnassignSourceToProductApiArg = {
  productSourceDto: ProductSourceDto;
};
export type UploadImageApiResponse = /** status 200 OK */ UploadImageResponse;
export type UploadImageApiArg = {
  body: {
    image?: Blob;
  };
};
export type GetImageApiResponse = /** status 200 OK */ GetImageResponse;
export type GetImageApiArg = {
  getImageRequest: GetImageRequest;
};
export type FindAllChangesApiResponse = /** status 200 OK */ PageChangeDto;
export type FindAllChangesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type InitAnalyseApiResponse = unknown;
export type InitAnalyseApiArg = void;
export type SetNotificationAsReadApiResponse = unknown;
export type SetNotificationAsReadApiArg = {
  id: string;
};
export type FindVariantsByProductIdOrSourceIdApiResponse =
  /** status 200 OK */ string[];
export type FindVariantsByProductIdOrSourceIdApiArg = {
  productId?: string;
  sourceId?: string;
};
export type FindAllSourcesBySourceTypeApiResponse =
  /** status 200 OK */ PageSourceDto;
export type FindAllSourcesBySourceTypeApiArg = {
  sourceType:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type FindSourceReadsApiResponse = /** status 200 OK */ PageSourceReadDto;
export type FindSourceReadsApiArg = {
  sourceId: string;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type FindSourceByIdApiResponse = /** status 200 OK */ SourceDto;
export type FindSourceByIdApiArg = {
  id: string;
};
export type GetSourceTypesApiResponse = /** status 200 OK */ SourceTypeDto[];
export type GetSourceTypesApiArg = void;
export type GetProductSourceReadTableApiResponse =
  /** status 200 OK */ ProductSourceReadTableDto[];
export type GetProductSourceReadTableApiArg = {
  productId: string;
  variantSize: string;
};
export type GetProductSourceReadChartApiResponse = /** status 200 OK */ {
  [key: string]: ProductSourceReadChartDto[];
};
export type GetProductSourceReadChartApiArg = {
  productId: string;
  variantSize: string;
  startDate: string;
  endDate: string;
};
export type FindProductByIdApiResponse = /** status 200 OK */ ProductDto;
export type FindProductByIdApiArg = {
  id: string;
};
export type DeleteProductApiResponse = unknown;
export type DeleteProductApiArg = {
  id: string;
};
export type FindAllNotificationsApiResponse =
  /** status 200 OK */ PageNotificationDto;
export type FindAllNotificationsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type FindNotificationByIdApiResponse =
  /** status 200 OK */ NotificationDto;
export type FindNotificationByIdApiArg = {
  id: string;
};
export type CountAllUnreadNotificationsApiResponse =
  /** status 200 OK */ CountNotificationDto;
export type CountAllUnreadNotificationsApiArg = void;
export type GetDashboardShopReviewApiResponse =
  /** status 200 OK */ DashboardShopReviewDto;
export type GetDashboardShopReviewApiArg = void;
export type GetDashboardKpiApiResponse = /** status 200 OK */ DashboardKpiDto;
export type GetDashboardKpiApiArg = void;
export type FindSourceChartBySourceIdApiResponse = /** status 200 OK */ {
  [key: string]: SourceChartDto[];
};
export type FindSourceChartBySourceIdApiArg = {
  sourceId: string;
};
export type FindShopsKpiChartBySourceTypeApiResponse = /** status 200 OK */ {
  [key: string]: ShopsKpiChartDto[];
};
export type FindShopsKpiChartBySourceTypeApiArg = void;
export type ProductDto = {
  id?: string;
  name?: string;
  photoUrl?: string;
};
export type SortObject = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  sort?: SortObject;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
};
export type PageProductDto = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: ProductDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  empty?: boolean;
};
export type SourceDto = {
  id?: string;
  sourceType?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  sourceTypeLabel?: string;
  url?: string;
  photoUrl?: string;
  name?: string;
  categoryName?: string;
  active?: boolean;
};
export type PageSourceDto = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: SourceDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  empty?: boolean;
};
export type ProductSourceDto = {
  productId?: string;
  sourceId?: string;
};
export type UploadImageResponse = {
  imageKey?: string;
  imageUrl?: string;
};
export type GetImageResponse = {
  imageUrl?: string;
};
export type GetImageRequest = {
  imageKey?: string;
};
export type ChangeDto = {
  id?: string;
  sourceType?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  sourceTypeLabel?: string;
  changeDate?: string;
  productName?: string;
  photoUrl?: string;
  variantSize?: string;
  priceBefore?: number;
  priceAfter?: number;
  changeRate?: number;
  cheapestPrice?: number;
  cheapestSourceType?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  cheapestSourceTypeLabel?: string;
};
export type PageChangeDto = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: ChangeDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  empty?: boolean;
};
export type SourceReadDto = {
  id?: string;
  sourceId?: string;
  readDate?: string;
  variantSize?: string;
  price?: number;
  stock?: number;
  availability?: boolean;
};
export type PageSourceReadDto = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: SourceReadDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  empty?: boolean;
};
export type SourceTypeDto = {
  code?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  name?: string;
};
export type ProductSourceReadTableDto = {
  productId?: string;
  sourceId?: string;
  sourceType?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  sourceTypeLabel?: string;
  variantSize?: string;
  latestPrice?: number;
  stock?: number;
};
export type ProductSourceReadChartDto = {
  productId?: string;
  sourceId?: string;
  sourceType?:
    | "GRAILPOINT_COM"
    | "SNEAKERSHOP91_COM"
    | "SNEAKERSJOINT_COM"
    | "KRKKICKS_PL"
    | "DORAWA_STORE"
    | "MRKICKS_PL"
    | "DROPSY_STORE"
    | "APLUG_PL";
  variantSize?: string;
  readDate?: string;
  price?: number;
  stock?: number;
};
export type NotificationDto = {
  id?: string;
  userId?: string;
  title?: string;
  content?: string;
  notificationDate?: string;
  read?: boolean;
};
export type PageNotificationDto = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: NotificationDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  empty?: boolean;
};
export type CountNotificationDto = {
  count?: number;
};
export type DashboardShopReviewDto = {
  lowestProductPriceCount?: number;
  allProductPriceCount?: number;
  possibleSavingInOtherShopsAmount?: number;
};
export type DashboardKpiDto = {
  analysedSourceTypeCount?: number;
  analysedProductCount?: number;
  cheapestProductCount?: number;
  cheapestProductCountDifferencePercentage?: number;
};
export type SourceChartDto = {
  readDate?: string;
  variantSize?: string;
  price?: number;
  stock?: number;
  availability?: boolean;
};
export type ShopsKpiChartDto = {
  readDate?: string;
  availableProducts?: number;
  availableVariants?: number;
  availableQuantities?: number;
};
export const {
  useScrapSourceMutation,
  useScrapBatchSourceReadMutation,
  useTriggerScrapSourcesMutation,
  useTriggerScrapReadsMutation,
  useFindAllProductsQuery,
  useCreateProductMutation,
  useFindProductSourcesByProductIdQuery,
  useAssignSourceToProductMutation,
  useUnassignSourceToProductMutation,
  useUploadImageMutation,
  useGetImageMutation,
  useFindAllChangesQuery,
  useInitAnalyseMutation,
  useSetNotificationAsReadMutation,
  useFindVariantsByProductIdOrSourceIdQuery,
  useFindAllSourcesBySourceTypeQuery,
  useFindSourceReadsQuery,
  useFindSourceByIdQuery,
  useGetSourceTypesQuery,
  useGetProductSourceReadTableQuery,
  useGetProductSourceReadChartQuery,
  useFindProductByIdQuery,
  useDeleteProductMutation,
  useFindAllNotificationsQuery,
  useFindNotificationByIdQuery,
  useCountAllUnreadNotificationsQuery,
  useGetDashboardShopReviewQuery,
  useGetDashboardKpiQuery,
  useFindSourceChartBySourceIdQuery,
  useFindShopsKpiChartBySourceTypeQuery,
} = injectedRtkApi;
