import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../app/routes";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.CHART_LINE}
                    onClick={() => navigate(`/${routes.PRODUCTS}/${row.productId}/${routes.CHART}`)}
                    label="Historia raport"
                />
            </div>
        </>
    );
};

const productSourceReadTableActionTemplate = (row: any) => <Actions row={row}/>;
export default productSourceReadTableActionTemplate;
