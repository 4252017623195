import React, {FC, useEffect, useState} from "react";
import KpiCard from "./KpiCard";
import Shops from "../shops/Shops";
import {useNavTitle} from "../../utils/navbarUtils";
import {useFindAllChangesQuery, useGetDashboardKpiQuery, useGetDashboardShopReviewQuery} from "../../api/mintpriceApi";
import ShowMoreButton from "../../components/button/ShowMoreButton";
import ChangeRow from "./ChangeRow";
import {Chart} from "primereact/chart";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";

const Home: FC = () => {
    useNavTitle("Dashboard", "Witaj ponownie. Sprawdź ostatnie zmiany na rynku");

    const navigate = useNavigate();

    const {data: changes} = useFindAllChangesQuery({page: 0, size: 4});
    const {data: shopReview} = useGetDashboardShopReviewQuery();
    const {data: shopKpi} = useGetDashboardKpiQuery();

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const data = {
            datasets: [
                {
                    data: [80, 20],
                    backgroundColor: [
                        "#1FFDA8",
                        "white"
                    ],
                    hoverBackgroundColor: [
                        "#1FFDA8",
                        "white"
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);


    return (
        <div style={{padding: 30, paddingRight: 30}}>
            <div className="flex flex-col justify-content-between items-center gap-8">
                <KpiCard title="Analizowane strony" value={shopKpi?.analysedSourceTypeCount ?? 0} key="card-kpi-1" icon="pi-user" color="#32594F"/>
                <KpiCard title="Analizowane produkty" value={shopKpi?.analysedProductCount ?? 0} key="card-kpi-2" icon="pi-verified" color="#FF747D"/>
                <KpiCard title="Twoje najtańsze produkty" value={shopKpi?.cheapestProductCount ?? 0} key="card-kpi-3" icon="pi-folder" color="#5D617C"/>
            </div>

            <Shops/>

            <div className="flex flex-row gap-8 mt-4">
                <div className="w-full">
                    <div className="flex flex-row justify-content-between">
                        <div style={{fontSize: 18, fontWeight: "bold"}}>Ostatnio modyfikowane</div>
                        <ShowMoreButton onClick={() => navigate(routes.CHANGES)}/>
                    </div>
                    <div className="w-full">
                        {changes?.content?.map((item) => (
                            <ChangeRow productName={item.productName ?? ""} key={item.id}
                                       imageSrc={item.photoUrl ?? ""}/>
                        ))}
                    </div>
                </div>
                <div className="w-full flex flex-column">
                    <div>
                        <div style={{fontSize: 18, fontWeight: "bold"}}>Ocena Twojego sklepu</div>
                    </div>
                    <div className="flex flex-row align-items-center justify-content-between  ">
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-column gap-2">
                                <div style={{fontSize: 17}}>
                                    Najniższe ceny
                                </div>
                                <div className="flex flex-row justify-content-start">
                                    <div style={{color: "red"}}>{shopReview?.lowestProductPriceCount}</div>
                                    <div style={{color: "gray"}}>/{shopReview?.allProductPriceCount} produktów</div>
                                </div>
                            </div>
                            <div className="flex flex-column gap-2">
                                <div style={{fontSize: 17}}>
                                    Twoi Klienci mogą zaoszczędzić u konkurencji
                                </div>
                                <div style={{color: "gray"}}>
                                    {shopReview?.possibleSavingInOtherShopsAmount} PLN
                                </div>
                            </div>
                        </div>
                        <div>
                            <Chart type="pie" data={chartData} options={chartOptions}
                                   className="w-full md:w-10rem"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
