import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {routes} from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import {useCreateProductMutation} from "../../api/mintpriceApi";
import {initialProductState, selectProductState, setData, setProductProperty} from "../../slices/productsSlice";
import UploadImageInput from "../../components/form/UploadImageInput";

const UpdateProduct: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [
        createProduct,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateProductMutation();

    const {product} = useAppSelector(selectProductState);
    const {name} = product;

    const isSuccess = isCreateSuccess;

    useEffect(() => {
        dispatch(setData({property: "product", value: initialProductState}));

        if (isSuccess) {
            navigate(`/${routes.PRODUCTS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        createProduct({productDto: product});
    };

    return (
        <div className="p-4">
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {`Dodaj produkt`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setProductProperty}
                />
                <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </div>
    );
};

export default UpdateProduct;
