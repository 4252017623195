import React, {FC} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {MenuItem, MenuItemOptions} from "primereact/menuitem";
import {Button} from "primereact/button";
import {useKeycloak} from "@react-keycloak-fork/web";
import {useAppSelector} from "../app/hooks";
import {selectNavbarState} from "../slices/navbarSlice";
import {Badge} from "primereact/badge";
import {useCountAllUnreadNotificationsQuery} from "../api/mintpriceApi";
import {routes} from "../app/routes";

const Nav = () => {
    const {keycloak} = useKeycloak();
    const {title, subtitle} = useAppSelector(selectNavbarState);

    const navigate = useNavigate();

    const {data: notificationCount} = useCountAllUnreadNotificationsQuery();

    return (
        <>
            <Menubar
                className="pt-4"
                style={{maxWidth: 1940, margin: "0 auto", backgroundColor: "white", borderColor: "transparent"}}
                start={
                    <div style={{paddingLeft: 15, paddingRight: 15}}>
                        <div style={{fontSize: 30, fontWeight: "bold", display: "flex", color: "black"}}>
                            {title}
                        </div>
                        <div style={{display: "flex", color: subtitle !== "" ? "gray" : "transparent"}}>
                            {subtitle !== "" ? subtitle : "-"}
                        </div>
                    </div>
                }
                end={
                    <div className="flex justify-content-center align-items-center gap-4">
                        <i className="pi pi-bell p-overlay-badge cursor-pointer" style={{fontSize: '2rem'}} onClick={() => navigate(routes.NOTIFICATIONS)}>
                            <Badge value={notificationCount?.count}></Badge>
                        </i>
                        <Button
                            label={
                                !!keycloak.authenticated
                                    ? "Logout " + keycloak.tokenParsed?.preferred_username
                                    : "Login"
                            }
                            icon="pi pi-power-off"
                            onClick={() =>
                                !!keycloak.authenticated ? keycloak.logout() : keycloak.login()
                            }
                            className="mr-3"
                        />
                    </div>
                }
            />
        </>
    );
};

export default Nav;
