import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import productActionTemplate from "./Actions";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {ProductDto, useFindAllProductsQuery} from "../../api/mintpriceApi";
import {routes} from "../../app/routes";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import TextInput from "../../components/form/TextInput";
import {selectProductState, setData} from "../../slices/productsSlice";
import {isAdmin} from "../../utils/loggedUserUtils";
import {useNavTitle} from "../../utils/navbarUtils";

const Products: FC<{}> = ({}) => {
    useNavTitle("Analizowane produkty", "W tym panelu widzisz wykres swoich produktów");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {productFilter} = useAppSelector(selectProductState);

    const {isLoading, data} = useFindAllProductsQuery({
        page: 0,
        size: 10000
    });

    const isDataLoading = isLoading;

    const columns = [
        {field: "name", header: "Nazwa", sortable: true, exportable: true},
        {field: "", header: "Zdjęcie", body: (row: ProductDto) => <img alt={row.name} src={row.photoUrl} width={100} />},
        {
            field: "",
            header: "",
            body: productActionTemplate,
            exportable: false
        },
    ];

    return (
        <div className="p-4">
            <div className="mt-2">
                <TextInput
                    id="productFilter"
                    name="productFilter"
                    label="Szukaj"
                    value={productFilter}
                    setProperty={setData}
                    placeholder="Wpisz frazę"
                />
            </div>
            <TableWrapper
                isLoading={isDataLoading}
                data={data?.content}
                columns={columns}
                addButton={<Button onClick={() => navigate(routes.ADD)} label="Dodaj" icon={PrimeIcons.PLUS}
                                   visible={isAdmin()}/>}
                stateKey="products-list"
                globalFilter={productFilter}
            />
        </div>
    );
};

export default Products;
