import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate, useParams} from "react-router-dom";
import productSourceActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";
import {useFindProductByIdQuery, useFindProductSourcesByProductIdQuery} from "../../../api/mintpriceApi";
import {isAdmin} from "../../../utils/loggedUserUtils";
import {useNavTitle} from "../../../utils/navbarUtils";

const ProductSources: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {id: productId = ""} = useParams();
    const {isLoading, data} = useFindProductSourcesByProductIdQuery({
        page: 0,
        size: 10000,
        productId: productId
    });
    const {data: productData} = useFindProductByIdQuery({
        id: productId
    });

    const columns = [
        {field: "sourceTypeLabel", header: "Źródło", sortable: true},
        {field: "name", header: "Nazwa na sklepie", sortable: true},
        {
            field: "",
            header: "",
            body: productSourceActionTemplate,
        },
    ];

    const headerButtons = (
        <>
            <Button onClick={() => navigate(`/${routes.PRODUCTS}`)} label="Wróc do produktów"
                    icon={PrimeIcons.BACKWARD}/>
            <Button onClick={() => navigate(routes.ADD)} label="Dodaj" icon={PrimeIcons.PLUS} visible={isAdmin()}/>
        </>
    );

    useNavTitle(`Źródła dla ${productData?.name}`, '');

    return (
        <div className="p-4">
            <TableWrapper
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                addButton={headerButtons}
                stateKey="product-sources-list"
            />
        </div>
    );
};

export default ProductSources;
