import keycloak from "../Keycloak";
import {UserRole} from "./ProtectedRoute";

export const userLoggedRole = (): UserRole => {
    // @ts-ignore
    const roles = keycloak.tokenParsed?.realm_access?.roles ?? [];

    if (roles.findIndex(item => item === "ADMIN") !== -1) return "ADMIN";

    return "USER";
}

export const isAdmin = () => {
    return userLoggedRole() === "ADMIN";
}

export const checkIfLoggedUserHaveAccess = (loggedRole: UserRole, role?: UserRole): boolean => {
    if (role === undefined) return true;

    if (loggedRole === "ADMIN") return true;

    return loggedRole === role;
}