import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate, useParams} from "react-router-dom";
import {useUnassignSourceToProductMutation} from "../../../api/mintpriceApi";
import {routes} from "../../../app/routes";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const {id: productId = ""} = useParams();
    const [visible, setVisible] = React.useState(false);

    const [unassignSource] = useUnassignSourceToProductMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.DOLLAR}
                    onClick={() => navigate(`/${routes.SOURCES}/${row.id}/${routes.READS}`)}
                    label="Zobacz raport cenowy"
                />
                <Button
                    label="Odłącz"
                    icon={PrimeIcons.TRASH}
                    onClick={() => setVisible(true)}
                />
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Jesteś pewny? Źródło zostanie odłączone od produktu"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={() => unassignSource({productSourceDto: {productId: productId, sourceId: row.id}})}
                />
            </div>
        </>
    );
};

const productSourceActionTemplate = (row: any) => <Actions row={row}/>;
export default productSourceActionTemplate;
