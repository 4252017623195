export const routes = {
  HOME: "",
  ADD: "add",
  EDIT: "edit",
  PRODUCTS: "products",
  SOURCES: "sources",
  SHOPS: "shops",
  READS: "reads",
  CHART: "chart",
  TABLE: "table",
  NOTIFICATIONS: "notifications",
  CHANGES: "changes",
  YOUR_PLAN: "your-plan"
};
