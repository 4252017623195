import React, {CSSProperties, FC, HTMLInputTypeAttribute} from "react";
import {useAppDispatch} from "../../app/hooks";
import {InputText} from "primereact/inputtext";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";

type TTextInputProps = {
    id: string;
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    style?: CSSProperties,
    index?: number,
    disabled?: boolean,
    type?: HTMLInputTypeAttribute,
    searchIcon?: boolean,
    placeholder?: string
};

const TextInput: FC<TTextInputProps> = ({
                                            id,
                                            name,
                                            value,
                                            label,
                                            setProperty,
                                            style,
                                            index,
                                            disabled,
                                            type,
                                            searchIcon = false,
                                            placeholder
                                        }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
        <span className={"p-float-label" + (searchIcon ? " p-input-icon-left w-full" : "")}>
            {searchIcon && <i  className="pi pi-search"/>}
            <InputText
                id={id}
                value={value}
                onChange={(e) =>
                    dispatch(setProperty({property: name, value: e.target.value, index: index}))
                }
                style={style}
                className={"w-full"}
                disabled={disabled}
                type={type}
                placeholder={placeholder}
            />
          <label htmlFor={name}>{label}</label>
        </span>
            </div>
        </>
    );
};

export default TextInput;
