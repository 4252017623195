import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import sourceActionTemplate from "./Actions";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {SourceDto, useFindAllSourcesBySourceTypeQuery, useGetSourceTypesQuery} from "../../api/mintpriceApi";
import {selectProductState, setData} from "../../slices/productsSlice";
import SelectInput from "../../components/form/SelectInput";
import TextInput from "../../components/form/TextInput";
import {useNavTitle} from "../../utils/navbarUtils";

const Sources: FC<{}> = ({}) => {
    useNavTitle("Analizowane strony", "");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {sourceType, sourceFilter} = useAppSelector(selectProductState);

    const {isLoading, data} = useFindAllSourcesBySourceTypeQuery({
        page: 0,
        size: 10000,
        sourceType: sourceType,
    });

    const isDataLoading = isLoading;

    const {data: sourceTypes} = useGetSourceTypesQuery();

    const columns = [
        {field: "name", header: "Nazwa", sortable: true, exportable: true},
        {field: "", header: "Zdjęcie", body: (row: SourceDto) => <img alt={row.name} src={row.photoUrl} width={100} />},
        {
            field: "",
            header: "",
            body: sourceActionTemplate,
            exportable: false
        },
    ];

    return (
        <div className="p-4">
            <div className="mt-5">
                <SelectInput
                    id="sourceType"
                    options={sourceTypes?.map(item => {
                        return {value: item.code, label: item.name}
                    }) ?? []}
                    name="sourceType"
                    value={sourceType}
                    label="Wybierz sklep"
                    setProperty={setData}
                />
            </div>
            <TextInput
                id="sourceFilter"
                name="sourceFilter"
                label="Szukaj"
                value={sourceFilter}
                setProperty={setData}
                placeholder="Wpisz frazę"
            />
            <TableWrapper
                isLoading={isDataLoading}
                data={data?.content}
                columns={columns}
                globalFilter={sourceFilter}
                stateKey="sources-list"
            />
        </div>
    );
};

export default Sources;
