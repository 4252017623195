import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type TNavbarState = {
    title: string
    subtitle: string
};

const initialState: TNavbarState = {
    title: '',
    subtitle: ''
};

export const navbarSlice = createSlice({
    name: "NAVBAR",
    initialState,
    reducers: {
        setData: <Property extends keyof TNavbarState>(
            state: TNavbarState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TNavbarState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        }
    },
});

export const {setData} = navbarSlice.actions;

export const selectNavbarState = (state: any) => state.navbar;

export default navbarSlice.reducer;
