import React, {FC, useState} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {useNavigate} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import {useSetNotificationAsReadMutation} from "../../api/mintpriceApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();

    const [visible, setVisible] = useState<boolean>(false);

    const [setsRead] = useSetNotificationAsReadMutation()

    const onClick = () => {
        setVisible(true);
        setsRead({id: row.id});
    }

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.INFO}
                    label="Zobacz szczegóły"
                    onClick={onClick}
                />
                <Dialog header={row.title} visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                    <p className="m-0">
                        {row.content}
                    </p>
                </Dialog>
            </div>
        </>
    );
};

const notificationActionTemplate = (row: any) => <Actions row={row}/>;
export default notificationActionTemplate;
