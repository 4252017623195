import {useEffect} from "react";
import {useAppDispatch} from "../app/hooks";
import {setData} from "../slices/navbarSlice";

export const useNavTitle = (title: string, subtitle?: string) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setData({property: "title", value: title}));
        dispatch(setData({property: "subtitle", value: subtitle ?? ""}));
    }, [title, subtitle]);
}