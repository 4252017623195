import React, {FC} from "react";
import {useNavTitle} from "../../utils/navbarUtils";

const YourPlan: FC<{}> = ({}) => {
    useNavTitle("Twój plan", "");

    return (
        <div className="p-4">
            <div className="font-bold">Twój plan to Mint Plus. Chcesz zmienić? Skontaktuj się z nami!</div>
            <div>halo@mintprice.io</div>
        </div>
    );
};

export default YourPlan;
