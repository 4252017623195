import React, { FC } from "react";
import {Card} from "primereact/card";
import { ProgressBar } from 'primereact/progressbar';
import ShowMoreButton from "../../components/button/ShowMoreButton";

const KpiCard: FC<{title: string, value: number, icon: string, color: string}> = ({title, value, icon, color}) => {

    return (
        <Card style={{border: "1px solid #eeeeee", borderRadius: 12, height: 200, padding: 0, boxShadow: "none"}} className={"w-full gap-5"}>
            <div className="flex flex-col justify-content-start align-items-center items-center justify-center gap-4">
                <i className={"pi " + icon} style={{color: "white", fontSize: '1.5rem', padding: 10, borderRadius: 50, backgroundColor: color}}></i>
                <div>
                    <p style={{fontSize: 14}}>
                        {title}
                    </p>
                    <p style={{fontSize: 18, fontWeight: "bold"}}>
                        {value}
                    </p>
                </div>
            </div>

            <ProgressBar color={"#20FDA8"} value={100} displayValueTemplate={() => ""} style={{height: 10}} />

            <div className="flex justify-content-end mt-4">
                <ShowMoreButton />
            </div>
        </Card>
    );
};

export default KpiCard;
