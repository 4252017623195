import React, {FC} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {SelectItemOptionsType} from "primereact/selectitem";

type TVariantInputProps = {
    id: string;
    options: SelectItemOptionsType,
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
};

const VariantInput: FC<TVariantInputProps> = (
    {
        id,
        options,
        name,
        setProperty,
        value,
    }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field field pt-2 pb-2 w-full">
                <div>Wybierz rozmiar do analizy</div>
                <div style={{display: "ruby"}}>
                    {options.map(option => {
                        return (
                            <div id={`${id}-option-${option.value}`} style={{
                                cursor: "pointer",
                                fontSize: 12,
                                fontWeight: "bold",
                                margin: 10,
                                padding: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                borderRadius: 12,
                                border: "1px solid gray",
                                color: option.value === value ? "white" : "black",
                                backgroundColor: option.value === value ? "black" : "white"
                            }}
                                 onClick={() => {
                                     dispatch(
                                         setProperty({property: name, value: option.value})
                                     )
                                 }}>{option.label}</div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default VariantInput;
