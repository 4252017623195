import React, {FC, useEffect} from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {Outlet, Route, Routes} from "react-router-dom";
import Home from "./tabs/home/Home";
import {ToastContainer} from "react-toastify";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import {routes} from "./app/routes";
import Nav from "./tabs/Navbar";
import Menu from "./tabs/Menu";
import Products from "./tabs/products/Products";
import UpdateProduct from "./tabs/products/UpdateProduct";
import ProductSources from "./tabs/products/sources/ProductSources";
import UpdateProductSource from "./tabs/products/sources/UpdateProductSource";
import SourceReads from "./tabs/sources/SourceReads";
import Shops from "./tabs/shops/Shops";
import Sources from "./tabs/sources/Sources";
import {useKeycloak} from "@react-keycloak-fork/web";
import {ProgressSpinner} from "primereact/progressspinner";
import ProtectedRoute from "./utils/ProtectedRoute";
import ProductSourceReadChart from "./tabs/products/reads/ProductSourceReadChart";
import ProductSourceReadTable from "./tabs/products/reads/ProductSourceReadTable";
import RightMenu from "./tabs/RightMenu";
import Notifications from "./tabs/notifications/Notifications";
import Changes from "./tabs/changes/Changes";
import YourPlan from "./tabs/yourplan/Sources";
import {addLocale, locale} from "primereact/api";
import {pl} from "./utils/localeUtils";

const App: FC = () => {
    const {initialized, keycloak} = useKeycloak();

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.login();
        }

        // Added new locale with its options
        addLocale("pl", pl);
        locale("pl"); // default locale.
    }, []);

    return (
        <>
            {!initialized ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressSpinner/>
                </div>
            ) : (
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div>
                                <div style={{
                                    display: "flex",
                                    maxWidth: 1920,
                                    margin: "0 auto",
                                    flexDirection: "row"
                                }}>
                                    <div
                                        style={{display: "flex", justifyContent: "flex-start", gap: 0, width: "100%"}}>
                                        <div style={{width: "20%", backgroundColor: "#F7F7F7", border: "1px solid #e5e7eb", height: "100vh"}}>
                                            <Menu/>
                                        </div>
                                        <div style={{width: "80%"}}>
                                            <Nav/>
                                            <Outlet/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        <Route path={routes.HOME} element={<Outlet/>}>
                            <Route
                                path={`/${routes.HOME}`}
                                element={
                                    <ProtectedRoute>
                                        <div style={{width: "100%", display: "flex"}}>
                                            <div className={"w-full"}>
                                                <div>
                                                    <Home/>
                                                </div>
                                            </div>
                                            <div style={{width: "25%", borderLeft: "1px solid #e5e7eb"}}>
                                                <RightMenu/>
                                            </div>
                                        </div>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.NOTIFICATIONS} element={<Outlet/>}>
                            <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <Notifications/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.SHOPS} element={<Outlet/>}>
                            <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <Shops/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.CHANGES} element={<Outlet/>}>
                            <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <Changes/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.YOUR_PLAN} element={<Outlet/>}>
                            <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <YourPlan/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.SOURCES} element={<Outlet/>}>
                        <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <Sources/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path={`:id/${routes.READS}`} element={<Outlet/>}>
                                <Route path={``}
                                       element={
                                           <ProtectedRoute>
                                               <SourceReads/>
                                           </ProtectedRoute>
                                       }
                                />
                            </Route>
                        </Route>
                        <Route path={routes.PRODUCTS} element={<Outlet/>}>
                            <Route
                                path={``}
                                element={
                                    <ProtectedRoute>
                                        <Products/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={`:id`} element={<Outlet/>}
                            >
                                <Route path={``}
                                       element={
                                           <ProtectedRoute role={"ADMIN"}>
                                               <UpdateProduct/>
                                           </ProtectedRoute>
                                       }
                                />
                                <Route
                                    path={`${routes.TABLE}`}
                                    element={
                                        <ProtectedRoute>
                                            <ProductSourceReadTable/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.CHART}`}
                                    element={
                                        <ProtectedRoute>
                                            <ProductSourceReadChart/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path={routes.SOURCES} element={<Outlet/>}>
                                    <Route path={``} element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <ProductSources/>
                                        </ProtectedRoute>
                                    }
                                    />
                                    <Route
                                        path={`${routes.ADD}`}
                                        element={
                                            <ProtectedRoute role={"ADMIN"}>
                                                <UpdateProductSource/>
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route
                                path={`${routes.ADD}`}
                                element={
                                    <ProtectedRoute role={"ADMIN"}>
                                        <UpdateProduct/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                    </Route>
                </Routes>
            )}
            <ToastContainer/>
        </>
    );
};

export default App;
