import React, {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Chart} from "primereact/chart";
import {
    useFindProductByIdQuery,
    useFindVariantsByProductIdOrSourceIdQuery,
    useGetProductSourceReadChartQuery, useGetSourceTypesQuery
} from "../../../api/mintpriceApi";
import {useAppSelector} from "../../../app/hooks";
import {selectProductState, setData} from "../../../slices/productsSlice";
import SelectInput from "../../../components/form/SelectInput";
import {getSourceTypeLabel} from "../../../utils/labels";
import {ProgressSpinner} from "primereact/progressspinner";
import {useNavTitle} from "../../../utils/navbarUtils";
import VariantInput from "../../../components/form/VariantInput";

var colors = [
    '#14b8a6', '#FF5733', '#33FF57', '#5733FF', '#FF33E9', '#33E9FF',
    '#FF3333', '#33FF33', '#3333FF', '#FFFF33', '#FF33FF',
    '#33FFFF', '#7F33FF', '#FF7F33', '#33FF7F', '#7FFF33',
    '#7F33FF', '#337FFF', '#FF337F', '#33FF7F', '#7FFF33'
];

const getRandomColor = (index: number) => {
    return colors[index];
}

const generateLast7Days = () => {
    var dates = [];
    var today = new Date();
    for (var i = 0; i < 8; i++) {
        var date = new Date(today);
        date.setDate(today.getDate() - i);
        var formattedDate = date.toISOString().slice(0, 10);
        dates.push(formattedDate);
    }
    dates.reverse();
    return dates;
}

const ProductSourceReadChart: FC<{}> = ({}) => {
    const {id: productId = ""} = useParams();
    const {sourceReadChartQuantityType, variantSize} = useAppSelector(selectProductState);

    const last7Days = generateLast7Days();

    const {data: variantSizes} = useFindVariantsByProductIdOrSourceIdQuery({productId: productId});
    const {data: sourceTypes} = useGetSourceTypesQuery();

    const {data: readsBySourceType = [], isFetching} = useGetProductSourceReadChartQuery({
        productId: productId,
        variantSize: variantSize,
        startDate: last7Days[0],
        endDate: last7Days[last7Days.length - 1]
    }, {skip: variantSize === "" || variantSize === null});

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const data = {
            labels: last7Days,
            datasets: Object.keys(readsBySourceType).map((sourceType, index) => {
                return {
                    label: getSourceTypeLabel(sourceType, sourceTypes),
                    // @ts-ignore
                    data: readsBySourceType[sourceType].map(item => {
                        return {
                            x: item.readDate,
                            y: sourceReadChartQuantityType === "price" ? item.price : item.stock
                        }
                    }),
                    fill: false,
                    borderColor: getRandomColor(index),
                    tension: 0.4
                }
            })
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    min: 0,
                    ticks: {
                        // @ts-ignore
                        callback: (label, index, labels) => {
                            return sourceReadChartQuantityType === "price" ? (+label).toFixed(2).toString().replace(".", ",") + ' zł' : label;
                        },
                        color: textColorSecondary,
                        stepSize: sourceReadChartQuantityType === "stock" ? 1 : undefined
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [readsBySourceType, variantSize, sourceReadChartQuantityType]);

    const {data: productData} = useFindProductByIdQuery({id: productId})

    const variantSizeOptions = variantSizes?.map(item => {
        return {value: item, label: item}
    }) || [];

    useNavTitle("Szczegołowa analiza produktu", 'W tym panelu widzisz wykres cenowy produktu');

    return (
        <div className="p-4">
            <div className="card">
                <div className="flex flex-row gap-3">
                    <div>
                        <h2 style={{width: 400}}>{productData?.name}</h2>
                        <img src={productData?.photoUrl} alt={productData?.name} width={300}/>
                    </div>
                    <div style={{width: "max-content", marginTop: 15}}>
                        <VariantInput
                            id="variantSize"
                            options={variantSizeOptions}
                            name="variantSize"
                            value={variantSize}
                            label=""
                            setProperty={setData}
                        />
                    </div>
                </div>
            </div>
            <div className="card">
                {
                    isFetching ? <ProgressSpinner style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}/> :
                        <Chart type="line" data={chartData} options={chartOptions}/>
                }
            </div>
        </div>
    );
};

export default ProductSourceReadChart;
