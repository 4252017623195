import {useKeycloak} from "@react-keycloak-fork/web";
import React, {FC, ReactNode} from "react";
import {NotAuthorized} from "../components/NotAuthorized";
import {checkIfLoggedUserHaveAccess, userLoggedRole} from "./loggedUserUtils";
import {Outlet} from "react-router-dom";

export type UserRole = "ADMIN" | "USER";

export const ProtectedRoute: FC<{ role?: UserRole, children: ReactNode }> = ({role, children}) => {
    const {keycloak, initialized} = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    if (initialized && !isLoggedIn) {
        keycloak.login();
    }

    const loggedRole = userLoggedRole();
    if (!checkIfLoggedUserHaveAccess(loggedRole, role)) {
        return <><NotAuthorized/></>
    }

    return <>{isLoggedIn ? children : <Outlet/>}</>;
};

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;
