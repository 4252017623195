import React, {FC} from "react";
import { Menu as PrimeMenu } from "primereact/menu";
import {Image} from "primereact/image";
import image from "../logo_mp.png";
import {MenuItem, MenuItemOptions} from "primereact/menuitem";
import {NavLink, useNavigate} from "react-router-dom";
import {routes} from "../app/routes";
import {userLoggedRole} from "../utils/loggedUserUtils";

const Template: FC<{ item: MenuItem, options: MenuItemOptions }> = ({
                                                                        item,
                                                                        options,
                                                                    }) => {
    return (
        <NavLink
            to={`${item.url}`}
            className={({isActive, isPending}) => isPending ? options.className : isActive ? options.className + " nav-link-active" : options.className}
            onClick={options.onClick}
            style={{padding: 10, marginLeft: 10}}
        >
            <i className={options.iconClassName} style={{paddingRight: 20}} />{" "}
            <span className={options.labelClassName}>{item.label}</span>
        </NavLink>
    );
};

export const template = (item: MenuItem, options: MenuItemOptions) => (
    <Template item={item} options={options} />
);

const Menu: FC<{}> = () => {
    const navigate = useNavigate();

    const items = [
        {
            template: () => {
                return (
                    <span className="flex justify-content-center align-items-center gap-1 pt-3">
                        <div style={{marginRight: 10, fontWeight: "bold", display: "flex"}}>
                            <Image src={image} height="30" style={{marginTop: 2}} className="cursor-pointer" onClick={() => navigate(routes.HOME)}/>
                        </div>
                    </span>
                );
            }
        },
        {
            label: 'Menu',
            style: {backgroundColor: "#F7F7F7"},
            items: [
                {
                    label: 'Panel',
                    url: "/",
                    icon: 'pi pi-file-edit',
                    shortcut: '⌘+N',
                    template: template
                },
                {
                    label: 'Powiadomienia',
                    url: "/notifications",
                    icon: 'pi pi-envelope',
                    shortcut: '⌘+N',
                    template: template
                },
                {
                    label: 'Analizowane strony',
                    url: "/sources",
                    icon: 'pi pi-folder-open',
                    shortcut: '⌘+N',
                    template: template
                },
                {
                    label: 'Analizowane produkty',
                    url: "/products",
                    icon: 'pi pi-chart-line',
                    shortcut: '⌘+N',
                    template: template
                },
                {
                    label: 'Ostatnie zmiany cenowe',
                    url: "/changes",
                    icon: 'pi pi-bolt',
                    shortcut: '⌘+N',
                    template: template
                },
                {
                    label: 'Twój plan',
                    url: "/your-plan",
                    icon: 'pi pi-wallet',
                    shortcut: '⌘+N',
                    template: template
                }
            ]
        },
        {
            label: userLoggedRole() === "ADMIN" ? 'Ustawienia i konfiguracja' : '',
            style: {backgroundColor: "#F7F7F7"},
            visible: userLoggedRole() === "ADMIN",
            items: [
                {
                    label: 'Ustawienia',
                    url: "/settings",
                    icon: 'pi pi-chart-line',
                    template: template,
                    visible: userLoggedRole() === "ADMIN",
                },
                {
                    label: 'Dodaj/usuń sklep',
                    url: "/articles",
                    icon: 'pi pi-chart-line',
                    template: template,
                    visible: userLoggedRole() === "ADMIN",
                }
            ]
        }
    ];

    return (
        <div className="card flex justify-content-center">
            <PrimeMenu style={{
                backgroundColor: "#F7F7F7",
                border: "none"
            }} model={items} className="w-full h-full md:w-25rem"/>
        </div>
    )
};

export default Menu;
