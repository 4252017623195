import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {api, API} from "../api/api";
import somethingSlice from "../slices/tmpPatternSlice";
import productsSlice from "../slices/productsSlice";
import navbarSlice from "../slices/navbarSlice";
import {displayMessage} from "./utils";

export const rootReducer = combineReducers({
    products: productsSlice,
    navbar: navbarSlice,
    somethings: somethingSlice,
    [api.reducerPath]: api.reducer,
});

export const middleware = [
    api.middleware,
    displayMessage
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = Omit<ReturnType<typeof store.getState>, typeof API>;
