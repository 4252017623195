import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductDto, ProductSourceDto, SourceDto} from "../api/mintpriceApi";

type TQuantityType = "product" | "variant" | "stock" | "price";

type TProductState = {
    product: ProductDto;
    source: SourceDto;
    productSource: ProductSourceDto;
    productSourceId: string;
    sourceType: SourceDto["sourceType"];
    quantityType: TQuantityType;
    shopKpiQuantityType: TQuantityType;
    sourceReadChartQuantityType: TQuantityType;
    sourceReadQuantityType: TQuantityType;
    aggregateType: string; // TODO added aggregate type
    variantSize: string | null;
    sourceFilter: string;
    productFilter: string;
};

export const initialProductState: ProductDto = {
    id: '',
    name: ''
};

export const initialSourceState: SourceDto = {
    id: '',
    name: '',
    url: ''
};

export const initialProductSourceState: ProductSourceDto = {
    productId: '',
    sourceId: ''
};

export const initialSourceTypeState: SourceDto["sourceType"] = "SNEAKERSJOINT_COM";

const initialState: TProductState = {
    product: initialProductState,
    source: initialSourceState,
    productSource: initialProductSourceState,
    productSourceId: '',
    sourceType: initialSourceTypeState,
    quantityType: "price",
    shopKpiQuantityType: "product",
    sourceReadChartQuantityType: "price",
    sourceReadQuantityType: "price",
    aggregateType: 'AVG',
    variantSize: null,
    sourceFilter: '',
    productFilter: ''
};

export const productsSlice = createSlice({
    name: "PRODUCT",
    initialState,
    reducers: {
        setData: <Property extends keyof TProductState>(
            state: TProductState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TProductState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setProductProperty: <Property extends keyof ProductDto>(
            state: TProductState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: ProductDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.product[property] = value;
        },
        setSourceProperty: <Property extends keyof SourceDto>(
            state: TProductState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: SourceDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.source[property] = value;
        },
        setProductSourceProperty: <Property extends keyof ProductSourceDto>(
            state: TProductState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: ProductSourceDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.productSource[property] = value;
        },
    },
});

export const {setData, setProductProperty, setSourceProperty, setProductSourceProperty} = productsSlice.actions;

export const selectProductState = (state: any) => state.products;

export default productsSlice.reducer;
